<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <employee-picker v-model="table.criteria.employees" multiple style="max-width: 400px" />
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="filteredItems"
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template slot="item" slot-scope="row">
            <tr :key="row.item.id">
              <td class="text-xs-left no-wrap">
                <employee-profile :employee="row.item.workplanRequest.employee" />
              </td>
              <td>
                {{ $t(`request_status.${row.item.workplanRequest.workplanRequestStatus}`) }}
              </td>
              <td>
                {{ row.item.workplanRequest.requestDateTime | formattedDate }}
              </td>
              <td class="text-center">
                <v-btn fab small depressed color="teal" @click.stop.prevent="handleDetailClick(row.item)">
                  <v-icon color="white" size="20">mdi-open-in-app</v-icon>
                </v-btn>
              </td>
              <td class="no-wrap">
                <v-btn small dark depressed color="primary" @click.stop="onClickLeaveApproval(row.item)">
                  {{ $t("buttons.action") }}
                </v-btn>
              </td>
              <td class="no-wrap">
                <cancel-workplan-request :id="row.item.workplanRequest.id" @success="fetchItems" />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <workplan-request-calendar v-model="detailDialog.status" :item="detailDialog.item" />
    <approval-confirm-dialog
      v-model="dialog.status"
      :data.sync="dialog.data"
      @close="onCloseDialog"
      @success="onSuccessApproval"
    />
  </v-row>
</template>

<script>
  import { ALL_PENDING_WORKPLAN_REQUESTS } from "../../query";

  export default {
    name: "WorkplanRequestApproval",
    components: {
      "approval-confirm-dialog": () => import("./components/confirm-dialog"),
      WorkplanRequestCalendar: () => import("./components/WorkplanRequestCalendar"),
      CancelWorkplanRequest: () => import("./components/CancelWorkplanRequest")
    },
    data: (vm) => ({
      dialog: {
        status: false,
        data: null
      },
      selectedDate: vm.$moment().startOf("month").format("YYYY-MM"),
      table: {
        loading: false,
        data: [],
        criteria: { employees: [] },
        selected: [],
        headers: [
          {
            text: vm.$t("time.overtime_approval.employee"),
            align: "left",
            sortable: false,
            value: "firstName",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("time.overtime_approval.request_status"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("time.overtime_approval.request_time"),
            align: "left",
            sortable: false,
            value: "requestDateTime",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("time.calendar.shift_detail"),
            align: "center",
            sortable: false,
            class: ["primary--text"]
          },
          {
            width: "1%",
            text: "",
            align: "right",
            sortable: false,
            class: ["primary--text"]
          },
          {
            width: "1%",
            text: "",
            align: "right",
            sortable: false,
            class: ["primary--text"]
          }
        ]
      },
      detailDialog: {
        status: false,
        item: {}
      }
    }),
    computed: {
      filteredItems() {
        return this.table.data.filter(({ workplanRequest }) => {
          const { employees } = this.table.criteria;
          const isEmployeeSelected = employees.length > 0 ? employees.includes(workplanRequest.employee.id) : true;

          return isEmployeeSelected;
        });
      }
    },
    methods: {
      handleDetailClick(item) {
        this.detailDialog.status = true;
        this.detailDialog.item = item;
      },
      onRefreshData() {
        this.fetchItems();
      },
      fetchItems() {
        this.table.loading = true;
        this.$apollo
          .query({
            query: ALL_PENDING_WORKPLAN_REQUESTS,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              data.employee.allPendingApprovalWorkplanRequests.forEach((workplanRequestProcess) => {
                workplanRequestProcess.workplanRequest.plannedWorkplans.forEach((workplan) => {
                  workplan.assignedShifts.forEach((shift) => {
                    if (!shift.beginTime && shift.shiftType === "FIX") {
                      shift.shiftType = "FLEXIBLE";
                    }
                    if (shift.shiftType === "DYNAMIC") {
                      shift.totalBreakTimeInMinutes = shift.totalBreakTimeInMinutesForWeb;
                    }
                  });
                });
              });
              this.table.data = data.employee.allPendingApprovalWorkplanRequests.sort((a, b) =>
                this.$moment(b.workplanRequest.requestDateTime).diff(a.workplanRequest.requestDateTime)
              );
            }
          })
          .catch(console.error)
          .finally(() => (this.table.loading = false));
      },
      onClickLeaveApproval(item) {
        this.dialog.data = item;
        this.dialog.status = true;
      },
      onCloseDialog() {
        this.dialog.data = null;
        this.dialog.status = false;
      },
      onSuccessApproval() {
        this.onCloseDialog();
        this.onRefreshData();
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>
